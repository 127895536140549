import { FC, useCallback, useEffect } from "react";
import hlsjs from "hls.js";
import styles from "./MediaStream.module.css";
import { capitalizeFirst } from "../../../app/utils";

interface Props {
  camera: string;
  stream: string;
}

export const MediaStream: FC<Props> = ({ camera, stream }) => {
  const handleInitHls = useCallback(async () => {
    if (!hlsjs.isSupported()) return;

    const video = document.getElementById(stream) as HTMLMediaElement;

    if (!video) return;

    const hls = new hlsjs({
      // --- Buffering Optimization ---
      maxBufferLength: 20, // Buffer 20 seconds of content
      maxBufferSize: 50 * 1000 * 1000, // Max buffer size 50MB
      maxMaxBufferLength: 40, // Max buffer time 40 seconds
      liveSyncDuration: 8, // Sync live playback 8 seconds behind
      liveMaxLatencyDuration: 10, // Max latency for live streams

      // --- Network Optimization ---
      manifestLoadingTimeOut: 5000, // Timeout for manifest loading
      fragLoadingTimeOut: 5000, // Timeout for fragment loading
      levelLoadingTimeOut: 5000, // Timeout for level loading
      manifestLoadingMaxRetry: 2, // Fewer retries for manifest
      fragLoadingMaxRetry: 2, // Fewer retries for fragments
      levelLoadingMaxRetry: 2, // Fewer retries for levels
      lowLatencyMode: true, // Enable low-latency streaming

      // --- Start Quality Selection ---
      startLevel: 0, // Start from lowest quality
      autoStartLoad: true, // Begin loading immediately
      capLevelToPlayerSize: true, // Match quality to player size
    });
    hls.loadSource(stream);
    hls.attachMedia(video);

    hls.on(hlsjs.Events.ERROR, (event, data) => {
      if (data.fatal) {
        switch (data.type) {
          case hlsjs.ErrorTypes.NETWORK_ERROR:
            console.error("Network error. Retrying...");
            hls.startLoad(); // Retry network errors
            break;
          case hlsjs.ErrorTypes.MEDIA_ERROR:
            console.error("Media error. Recovering...");
            hls.destroy(); // Destroy the HLS instance
            hls.attachMedia(video); // Reattach the media element
            break;
          default: // Destroy player on unrecoverable error
            console.error("Fatal error:", data);
            hls.destroy();
            break;
        }
      }
    });

    return hls;
  }, [stream]);

  useEffect(() => {
    handleInitHls();
  }, [handleInitHls]);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{capitalizeFirst(camera)}</h3>
      <video
        preload="auto"
        className={styles.video}
        controls
        autoPlay
        muted
        id={stream}
      />
    </div>
  );
};
